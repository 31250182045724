import Image from 'next/image'
import Link from 'next/link'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const WhatIsMC = ({ header }) => {
  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.whatIsMC.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.whatIsMC.text1')}</p>
          <p className={styles.note}>
            {intl.t('faq.pages.whatIsMC.text2')}
            <Link href='/' legacyBehavior>
              <a
                className={styles.link}
                rel='noopener noreferer'
                target='_blank'
              >
                {intl.t('faq.pages.whatIsMC.link')}
              </a>
            </Link>
          </p>
          <Image
            src='/assets/images/faq/what-is-mc.png'
            width='770'
            height='400'
            alt='Coin360 crypto heatmap: sort crypto by market cap & volume'
          />
          <p>{intl.t('faq.pages.whatIsMC.text3')}</p>
          <p className={styles.fact}>{intl.t('faq.pages.whatIsMC.text4')}</p>
          <p>{intl.t('faq.pages.whatIsMC.text5')}</p>
        </section>
      </div>
    </>
  )
}

export default WhatIsMC
