import React, { useEffect } from 'react'

const AdSidebar = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://cdn.adx.ws/scripts/loader.js'
    document.body.appendChild(script)

    const adScript = document.createElement('script')
    adScript.innerHTML = `
      window.sevioads = window.sevioads || [];
      var sevioads_preferences = [];
      sevioads_preferences[0] = {};
      sevioads_preferences[0].zone = "746c7619-34ad-42c8-b2f9-ada652e7ecfe";
      sevioads_preferences[0].adType = "banner";
      sevioads_preferences[0].inventoryId = "006044c7-662f-44ee-8088-48943fb71bcb";
      sevioads_preferences[0].accountId = "542bf6ba-fa5f-49cb-b028-b9589d251b56";
      window.sevioads.push(sevioads_preferences);
    `
    document.body.appendChild(adScript)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(adScript)
    }
  }, [])

  return (
    <div style={{ width: '160px', marginRight: '20px' }}>
      <div
        className='sevioads'
        data-zone='746c7619-34ad-42c8-b2f9-ada652e7ecfe'
      ></div>
    </div>
  )
}

export default AdSidebar
