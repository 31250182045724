import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import useAuth from 'src/hooks/useAuth'
import useWidth from 'src/hooks/useWidth'
import { FooterNavbar } from '../FooterNavbar'
import { MainWidget } from './Widgets/MainWidget'
import { NewsFeedWidget } from './Widgets/NewsFeed'
import { StatisticsWidget } from './Widgets/Statistics'
import { RangoSwap } from '../RangoSwap'
import styles from './styles.module.scss'

// Keep your Rango config here, or pass it in from props

// Popup Component (unchanged)
const SignUpPopup = ({ onClose }) => {
  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.popup}>
        <Image
          className={styles.closeIcon}
          src='./assets/icons/icon-close.svg'
          width={25}
          height={25}
          alt='close'
          onClick={onClose}
        />
        <div className={styles.header}>Subscribe to our weekly newsletter</div>
        <iframe
          src='https://cdn.forms-content.sg-form.com/98388cb6-dd00-11ed-bba7-b2a480f00fab'
          width='100%'
          height='100%'
          frameBorder='0'
        />
      </div>
    </div>
  )
}

export const RightSidebar = ({ setIsSidebarOpened }) => {
  const { isMobile } = useWidth()
  const [rightSidebar, setRightSidebar] = useState(!isMobile)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { isAuthorized } = useAuth()

  // Example: remove all direct Rango event logic from here
  // because RangoWidget now handles it.

  // If user is on mobile, hide the sidebar
  useEffect(() => {
    if (isMobile) {
      setRightSidebar(false)
      setIsSidebarOpened(false)
    }
  }, [isMobile, setIsSidebarOpened])

  return (
    <>
      <div
        className={clsx(styles.rightSidebarButtom, {
          [styles.rightSidebarButtomClosed]: !rightSidebar,
        })}
      >
        <Image
          src={`/assets/icons/icon-right-sidebar-${
            rightSidebar ? 'closed' : 'open'
          }.svg`}
          width='20'
          height='20'
          alt='sidebar icon'
          onClick={() => {
            setRightSidebar(!rightSidebar)
            setIsSidebarOpened(!rightSidebar)
          }}
        />
      </div>

      <div
        className={clsx(styles.rightSidebar, {
          [styles.rightSidebarOpen]: rightSidebar,
        })}
      >
        {rightSidebar && (
          <>
            <div className={styles.widgetsContainer}>
              <MainWidget
                title={
                  <div className={styles.pointsContainer}>
                    <div className={styles.logoContainer}>
                      <Image
                        src='/assets/points-logo.svg'
                        width={25}
                        height={25}
                        alt='points'
                      />
                    </div>
                    <div className={styles.pointsText}>
                      Swap and Earn Points
                    </div>
                  </div>
                }
              >
                {/* Use your new RangoWidget here */}
                <RangoSwap />
              </MainWidget>

              {!isMobile && <NewsFeedWidget />}
              <StatisticsWidget />
            </div>

            <FooterNavbar />
          </>
        )}
      </div>

      {isPopupOpen && <SignUpPopup onClose={() => setIsPopupOpen(false)} />}
    </>
  )
}
