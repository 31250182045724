import React, { useState } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Image from 'next/image'

type MainWidgetProps = {
  title: React.ReactNode
  tabs?: React.ReactNode
  children: React.ReactNode
  classNameOpen?: string
  defaultOpen?: boolean // <--- add this
}

export const MainWidget = ({
  title,
  tabs,
  children,
  classNameOpen,
  defaultOpen = true, // <--- default to true
}: MainWidgetProps) => {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <div
      className={clsx(
        styles.widget,
        { [styles.widgetOpen]: open },
        { [classNameOpen]: open }
      )}
    >
      <div className={styles.header}>
        <div className={styles.headerLogo}>
          <Image
            src='/assets/icons/icon.svg'
            width={20}
            height={20}
            alt='Logo'
          />
        </div>
        {title}
        <Image
          className={styles.closeIcon}
          src={`/assets/icons/icon-${open ? 'minus' : 'plus'}.svg`}
          width='22'
          height='22'
          alt='sidebar icon'
          onClick={() => setOpen(!open)}
        />
      </div>
      {tabs && tabs}
      <div className={styles.content}>{children}</div>
    </div>
  )
}
