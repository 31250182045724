import Image from 'next/image'
import { intl } from 'src/i18n'
import styles from './styles.module.scss'

const baseUrl = '/assets/flags/'
const flags = ['BTC', 'ETH', 'EOS', 'USDT', 'GBP', 'JPY', 'CNY']

export const Volume = ({ header }) => {
  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.volume.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.volume.text1')}</p>
          <p>{intl.t('faq.pages.volume.text2')}</p>
          <p className={styles.note}>
            {intl.t('faq.pages.volume.text3')}
            {flags.map(flag => (
              <span key={flag} className={styles.currency}>
                <span>
                  <Image
                    src={`${baseUrl}${flag}.png`}
                    alt={`${flag}`}
                    width='16'
                    height='16'
                  />
                </span>
                <span>{flag}, </span>
              </span>
            ))}
            {intl.t('faq.pages.volume.text4')}
          </p>
          <p className={styles.fact}>{intl.t('faq.pages.volume.text5')}</p>
        </section>
      </div>
    </>
  )
}

export default Volume
