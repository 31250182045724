import { useState } from 'react'
import clsx from 'clsx'
import { faqRootConfig } from '../../faqconfig'
import styles from './styles.module.scss'
import Image from 'next/image'

const faqItems = faqRootConfig.children

const FaqRootLink = ({ data, text, isMobile }) => {
  const [openQuestion, setOpenQuestion] = useState(null) // Track which question is open

  const toggleQuestion = key => {
    setOpenQuestion(prev => (prev === key ? null : key)) // Toggle only the selected question
  }

  return (
    <div key={text} className={styles.part}>
      <div className={styles.headerWrapper}>
        <h2 className={styles.partheader}>{text}</h2>
      </div>
      <div className={styles.linksPart}>
        {data.map(({ key, viewComponent: ViewComponent, text: textLink }) => (
          <div key={key} className={styles.linkItem}>
            <div
              className={styles.linkHeader}
              onClick={() => toggleQuestion(key)} // Toggle only for this question
            >
              <h3>{textLink}</h3>
              <div
                className={clsx(styles.icon, {
                  [styles.isOpen]: openQuestion === key, // Rotate icon only for open question
                })}
              >
                <Image
                  src='/assets/icons/icon-arrow-down.svg'
                  width='20'
                  height='20'
                  alt='Arrow icon'
                />
              </div>
            </div>
            {openQuestion === key && (
              <div className={styles.content}>
                <ViewComponent /> {/* Render the component inline */}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export const FaqRootLinks = ({ isMobile }) => {
  return (
    <div className={styles.container}>
      {faqItems.map(({ text, children }) => (
        <FaqRootLink
          key={text}
          data={children}
          text={text}
          isMobile={isMobile}
        />
      ))}
    </div>
  )
}

export default FaqRootLinks
