import React, { useEffect, useState } from 'react'
import { setCookies, getCookie } from 'cookies-next'
import Image from 'next/image'
import styles from './styles.module.scss'

const BETA_BUCKET_KEY = 'betaBucket'
const BETA_OVERLAY_CLOSED_KEY = 'betaOverlayClosed'
const BUCKET_PERCENT = 0.75 // 25% bucket

const BetaOverlay = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // 1) Check if user has previously closed the overlay
    const isClosed = getCookie(BETA_OVERLAY_CLOSED_KEY)
    if (isClosed) {
      return // user closed it => don't show
    }

    // 2) Check if the user is in our 25% bucket
    //    a) get the existing bucket from cookies
    let bucket = getCookie(BETA_BUCKET_KEY)

    //    b) if none, randomly assign and store
    if (!bucket) {
      const randomValue = Math.random()
      bucket = randomValue < BUCKET_PERCENT ? '1' : '0'
      setCookies(BETA_BUCKET_KEY, bucket, {
        maxAge: 365 * 24 * 60 * 60, // 1-year cookie
      })
    }

    // 3) If bucket === '1', show the overlay
    if (bucket === '1') {
      setIsVisible(true)
    }
  }, [])

  const handleClose = () => {
    setIsVisible(false)
    // We store a separate cookie indicating the user manually closed it
    setCookies(BETA_OVERLAY_CLOSED_KEY, 'true', { maxAge: 7 * 24 * 60 * 60 })
  }

  // If user is not in the bucket or they’ve closed it, isVisible = false => null
  if (!isVisible) return null

  return (
    <div
      className={styles.overlayContainer}
      role='dialog'
      aria-labelledby='beta-overlay-title'
    >
      <div className={styles.overlayContent}>
        <button
          onClick={handleClose}
          className={styles.closeButton}
          aria-label='Close overlay'
        >
          <Image
            src='/assets/icons/icon-close-cross.svg'
            alt='Close'
            width={16}
            height={16}
          />
        </button>
        <Image
          src='/assets/beta-logo.svg'
          height={130}
          width={130}
          alt='beta'
        />
        <h2 id='beta-overlay-title' className={styles.overlayTitle}>
          Coin360 is getting an <span className={styles.upgrade}>upgrade!</span>
        </h2>

        <p className={styles.overlayText}>
          Try our new Beta and share your feedback. <br />
          Thank you for your support!
        </p>

        <div className={styles.cardsContainer}>
          <div className={styles.card}>
            <Image
              src='/assets/dashboard.svg'
              width={40}
              height={40}
              alt='dashboard'
            />
            <p>Dashboards</p>
          </div>
          <div className={styles.card}>
            <Image
              src='/assets/widget.svg'
              width={40}
              height={40}
              alt='widget'
            />
            <p>Widgets</p>
          </div>
          <div className={styles.card}>
            <Image src='/assets/swap.svg' width={40} height={40} alt='swap' />
            <p>Swaps</p>
          </div>
          <div className={styles.card}>
            <Image src='/assets/point.svg' width={40} height={40} alt='point' />
            <p>Points</p>
          </div>
        </div>

        <a
          href='https://beta.coin360.com/'
          target='_blank'
          rel='noopener noreferrer nofollow'
        >
          <button className={styles.betaButton}>Try our Beta!</button>
        </a>
      </div>
    </div>
  )
}

export default BetaOverlay
