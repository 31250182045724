import React, { useEffect, useState, useRef } from 'react'
import dynamic from 'next/dynamic'
import { useSelector } from 'react-redux'
import { AutoSizer } from 'react-virtualized'
import CoinsFilterLayout from 'src/layouts/CoinsFilterLayout'
import { MainLayout } from 'src/layouts'
import NewsModal from '../../components/NewsModal'
import Image from 'next/image'
import { Loader } from 'src/components/Loader'
import { FooterMobile } from 'src/components/FooterMobile'
import { ShareMapHeader } from 'src/components/ShareMapHeader'
import {
  getFilteredMapDataTree,
  getCoinsGroup,
  getCoinsRange,
  getCoinsExceptions,
  getCoinsDisplay,
  getCoinsActivePeriod,
  getCoinsDependsOn,
  getCoinsTop,
  getCurrentCurrencySelector,
  getGlobals,
  getFullScreenState,
  getCoinsGlobalSelector,
} from 'src/redux/selectors'
import { TREEMAP_ID, ENTITY, PERIODS } from 'src/consts'
import { formatGlobals } from 'src/utils/currency'
import useFetchMapData from 'src/hooks/useFetchMapData'
import useWidth from 'src/hooks/useWidth'
import styles from './styles.module.scss'
import { useRouter } from 'next/router'
import { intl } from 'src/i18n'
import { TestSidebar } from 'src/components/RightSidebar/TestSidebar'
import { TableView } from 'src/components/TableView'
import { RightSidebar } from 'src/components/RightSidebar'
import { useConnectedState } from 'src/components/TableView/hooks/redux'
import { useDispatch } from 'react-redux'
import { setSharedCurrency } from 'src/redux/thunks'
import AdSidebar from 'src/components/AdSidebar'
import FaqRoot from '../Faq/components/FaqRoot'
import BetaOverlay from 'src/components/BetaOverlay'
const TreeMap = dynamic(() => import('src/components/TreeMap'), { ssr: false })

const title = intl.t('main.seo.title')
const description = intl.t('main.seo.description')

const Main = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { isMobile } = useWidth()

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <MainLayout title={title} description={description} isHeatmap>
      {!isMobile && <BetaOverlay />}
      <Test modalOpen={modalOpen} toggleModal={toggleModal} />
    </MainLayout>
  )
}

const Test = ({ modalOpen, toggleModal }) => {
  const router = useRouter()
  const activePeriod = useSelector(getCoinsActivePeriod)
  const dataType = PERIODS.dataKeys[activePeriod]
  const currency = useSelector(state => getCurrentCurrencySelector(state))
  const globals = useSelector(getGlobals)
  const [isLoading, setIsLoading] = useState(true)
  const { dominance } = formatGlobals(globals, currency)
  const fullScreen = useSelector(getFullScreenState)
  const { isMobile } = useWidth()
  const isShare = router.asPath.includes('getScreen')
  const [isSidebarOpened, setIsSidebarOpened] = useState(true)
  const layoutRef = useRef(null)
  const connectedState = useConnectedState(ENTITY.COIN)
  const coinsData = connectedState.data
  const globalData = useSelector(getCoinsGlobalSelector)

  const onPageChange = () => {
    layoutRef.current?.scrollToTop()
  }
  useEffect(() => {
    setIsLoading(false)
  }, [])

  useFetchMapData({ entity: ENTITY.COIN })

  const viewProps = {
    id: TREEMAP_ID,
    isMobile,
    widget: undefined,
    topLeaderboardHeight: undefined,
    currency,
    dataType,
    autoUpdate: false,
    baseValue: useSelector(getCoinsDependsOn),
    activePeriod,
    filteredTree: useSelector(getFilteredMapDataTree),
    slices: useSelector(getCoinsDisplay),
    topLoosersAndGainers: useSelector(getCoinsTop),
    blockSizeRange: useSelector(getCoinsRange),
    exceptions: useSelector(getCoinsExceptions),
    entity: ENTITY.COIN,
    groupFilter: useSelector(getCoinsGroup),
    dominance,
  }

  return (
    <>
      <h2 className={styles.seoTags}>
        Visualize the Crypto Market with COIN360&apos;s Comprehensive Heatmap
      </h2>
      <h2 className={styles.seoTags}>Discover Current Cryptocurrency Prices</h2>
      <h2 className={styles.seoTags}>
        Explore Market Capitalizations of Different Coins
      </h2>
      {!fullScreen && !isShare && (
        <CoinsFilterLayout
          classNames={[styles.filters]}
          isSidebarOpened={isSidebarOpened}
        />
      )}
      <div className={styles.contentPage}>
        <div className={styles.content}>
          {isShare && <ShareMapHeader />}
          <div
            className={styles.container}
            style={{
              pointerEvents: isMobile ? 'none' : 'auto',
              touchAction: isMobile ? 'auto' : 'pan-y',
            }}
          >
            <div className={styles.mapbox}>
              <div className={styles.buttons}>
                {/* <ShareButton className={styles.share}></ShareButton> */}
              </div>
              <AutoSizer
                defaultWidth={viewProps.isMobile ? 320 : 1280}
                defaultHeight={1024}
              >
                {({ height, width }) => {
                  return (
                    <TreeMap width={width} height={height} {...viewProps} />
                  )
                }}
              </AutoSizer>
            </div>
          </div>
          {/* <div className={styles.newsButtonContainer}>
            {modalOpen && <NewsModal onClose={toggleModal} />}{' '}
            <button className={styles.newsButton} onClick={toggleModal}>
              Live News
              <Image
                className={styles.closeIcon}
                src='/assets/icons/icon-arrow-up-new.svg'
                width='15'
                height='15'
                alt='Open arrow'
              />
            </button>{' '}
          </div> */}
        </div>
        {!isShare && !isMobile && (
          <RightSidebar setIsSidebarOpened={setIsSidebarOpened} />
        )}
      </div>

      <TableView
        currency={currency}
        onPageChange={onPageChange}
        activePeriod={activePeriod}
        entity={ENTITY.COIN}
        entitiesData={coinsData}
        globalData={globalData}
      />
      <div className={styles.mainContent}>
        <div className={styles.contentBlock}>
          <h2>Welcome to COIN360: Your Ultimate Cryptocurrency Perspective</h2>
          <p>
            COIN360, the main source of cryptocurrency prices on a visually
            appealing heatmap. We aggregate prices from the most trusted sources
            and allow you to always be one step ahead by getting an instant view
            of the entire market or specific sectors on various timeframes. We
            also offer interactive charts, and comprehensive tools to help you
            make informed decisions. Whether you’re tracking your favorite coins
            using our watchlists, if you are exploring the latest crypto prices,
            or keeping yourself updated on crypto exchanges; our goal is to
            provide a seamless experience for every crypto enthusiast.
          </p>
          <br />
          <h3>Stay Informed with Market Insights and Price Tracking</h3>
          <p>
            Properly understanding the cryptocurrency market can be complex and
            take time, so that is why we make it simpler by focusing on key
            metrics like prices, market caps, and volume. For both beginners and
            seasoned investors, the price action is an essential indicator of
            the market’s overall health and activity. By aggregating data from
            top exchanges, COIN360 provides a detailed snapshot of the market
            caps, price movements, and trading volumes of over 5000 tokens.
          </p>
          <br />
          <h3>Explore Powerful Tools and Interactive Features</h3>
          <p>
            COIN360 isn’t only about numbers; it’s about giving you the tools
            you need to engage and interact with data as well as content in
            meaningful ways. Our platform includes:
          </p>
          <h4>Heatmaps and Charts:</h4>
          <p>
            Discover price changes and market trends at a glance. Our heatmap
            offers a unique and intuitive way to understand market moves, while
            our charts gives a more detailed view on coins, making it easy to
            spot top gainers and losers on various timeframes such as hourly,
            daily and weekly.
          </p>
          <h4>Crypto News:</h4>
          <p>
            For those seeking the latest crypto news, we make sure to provide
            daily and weekly articles covering the most important happenings in
            the market, ensuring that you’re always informed of breaking updates
            and changes.
          </p>
          <h4>Exchange Trackers:</h4>
          <p>
            With so many crypto exchange options available, COIN360 simplifies
            your search by providing insights on major exchanges and their
            current trading pairs, volumes, and fees for each coin we track on
            our website. We track the most relevant exchanges and ensure
            reliable prices that update in real time.
          </p>
          <br />
          <h3>Learn with COIN360’s Educational Resources</h3>
          <p>
            Cryptocurrencies and its market can be hard to fully understand,
            which is why we are putting offers a range of educational resources
            under our Learning section, which includes:
          </p>
          <h4>Glossary section:</h4>
          <p>
            Our glossary section was initially built to simply define basic
            crypto terms - but evolved into providing detailed explanations and
            insights for each concept, helping you to build a solid
            understanding of the cryptocurrency landscape and understand more in
            depth what terms really mean. Whether you’re new to crypto or
            looking to deepen your knowledge, our glossary makes it easy to
            navigate complex terminology with confidence.
          </p>
          <h4>How To Guide:</h4>
          <p>
            The How-To guides, located in the learn section, are made to assist
            users of any experience level with help on ‘how to’ perform certain
            actions, such as, on-chain transactions, navigate exchanges, or
            complete other crypto-related activities. Our comprehensive guides
            provide clear, step-by-step instructions accompanied with images.
            This resource empowers everyone—from beginners to experts—to get the
            most of crypto with ease and confidence.
          </p>
          <h4>Reviews and Toplists:</h4>
          <p>
            With so many crypto projects and products available, it can be hard
            to decide which ones are the best fit for your specific needs. Which
            is why we’ve created an extensive section specifically for reviewing
            and ranking projects in the market. From dApps and staking solutions
            to Telegram trading bots and exchanges, we provide in-depth reviews
            and curated top lists to help you confidently choose the right
            products in the crypto space.
          </p>
        </div>
      </div>
      <div className={styles.faqs}>
        <FaqRoot />
      </div>
      <Loader isActive={isLoading} />
      {/* {isMobile && !isShare && <FooterMobile />} */}
    </>
  )
}

export default Main
