import Image from 'next/image'
import Link from 'next/link'
import styles from './styles.module.scss'
import { stringToHtml } from 'src/i18n/formatHtml'
import { intl } from 'src/i18n'

export const TopMC = ({ header }) => (
  <>
    <h1 className={styles.header}>{header}</h1>
    <div className={styles.container}>
      <div className={styles.content}>{intl.t('faq.pages.topMC.header')}</div>
      <section>
        <p>{intl.t('faq.pages.topMC.text1')}</p>
        <p className={styles.note}>
          COIN360’s&nbsp;
          <Link href='/coin' legacyBehavior>
            <a className={styles.link} rel='noopener noreferer' target='_blank'>
              {intl.t('faq.pages.topMC.link')}
            </a>
          </Link>
          {intl.t('faq.pages.topMC.text2')}
        </p>
        <Image
          src='/assets/images/faq/top-mc-list.png'
          width='770'
          height='368'
          alt='Total crypto market cap for top 100 cryptocurrencies'
        />

        <p
          dangerouslySetInnerHTML={stringToHtml(
            intl.t('faq.pages.topMC.text3')
          )}
        />

        <Image
          src='/assets/images/faq/top-mc-graph.png'
          width='770'
          height='313'
          alt='Total crypto market cap and volume, USD - chart on Coin360'
        />
        <p className={styles.fact}>{intl.t('faq.pages.topMC.text4')}</p>
      </section>
    </div>
  </>
)

export default TopMC
