import Image from 'next/image'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const CalculateChange = ({ header }) => {
  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.calculateChange.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.calculateChange.text1')}</p>
          <p className={styles.note}>
            {intl.t('faq.pages.calculateChange.text2')}
          </p>
          <Image
            src='/assets/images/faq/calculate-change.png'
            width='770'
            height='319'
            alt='Coin360 crypto heatmap: sort crypto by market cap & volume'
          />
          <p>{intl.t('faq.pages.calculateChange.text3')}</p>
          <p>{intl.t('faq.pages.calculateChange.text4')}</p>
        </section>
      </div>
    </>
  )
}

export default CalculateChange
