import clsx from 'clsx'
import Image from 'next/image'
import useWidth from 'src/hooks/useWidth'
import SharePage from 'src/components/SharePage'
import { useOverlayTriggerState } from '@react-stately/overlays'
import styles from './styles.module.scss'

export const ShareButton = ({ children, className = '', noIcon = false }) => {
  const share = useOverlayTriggerState({})
  const { isMobile } = useWidth()

  return (
    <>
      <SharePage open={share.isOpen} onClose={share.close} />
      <div
        className={clsx(styles.shareButton, className, {
          [styles.isMobile]: isMobile,
        })}
        onClick={share.open}
      >
        {!noIcon && (
          <div className={styles.shareIcon}>
            <Image
              src='/assets/social/icon-share.svg'
              width='20'
              height='18'
              alt='Share icon'
            />
          </div>
        )}
        {children && (
          <div className={clsx(styles.children, { [styles.noIcon]: noIcon })}>
            {children}
          </div>
        )}
      </div>
    </>
  )
}

export default ShareButton
