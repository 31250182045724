import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import useWidth from 'src/hooks/useWidth'
import { FooterNavbar } from '../../FooterNavbar'
import { NewsFeedWidget } from '../Widgets/NewsFeed'
import styles from './styles.module.scss'

// Popup Component
const SignUpPopup = ({ onClose }) => {
  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.popup}>
        <Image
          className={styles.closeIcon}
          src='./assets/icons/icon-close.svg'
          width={25}
          height={25}
          alt='close'
          onClick={onClose} // Add this line to ensure the icon can close the popup
        />
        <div className={styles.header}>Subscribe to our weekly newsletter</div>
        <iframe
          src='https://cdn.forms-content.sg-form.com/98388cb6-dd00-11ed-bba7-b2a480f00fab'
          width='100%'
          height='100%'
        />
      </div>
    </div>
  )
}

export const TestSidebar = ({ setIsSidebarOpened }) => {
  const { isMobile } = useWidth()
  const [rightSidebar, setRightSidebar] = useState(!isMobile)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [showNewsFeed, setShowNewsFeed] = useState(true)

  useEffect(() => {
    if (isMobile) {
      setRightSidebar(false)
      setIsSidebarOpened(false)
    }
  }, [isMobile])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight <= 770) {
        setShowNewsFeed(false)
      } else {
        setShowNewsFeed(true)
      }
    }

    handleResize() // Check on initial render
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const jup = window['Jupiter']
    if (jup) {
      jup.init({
        displayMode: 'integrated',
        integratedTargetId: 'integrated-terminal',
        endpoint:
          'https://solana-mainnet.g.alchemy.com/v2/238v0qVrYW58RgpW1_iHVCRVIVsfmHTX',
        platformFeeAndAccounts: {
          referralAccount: 'CCv7CMt83LWtNo4Fti7nvMVotnmt5CVCbwhXWSvHZ9oJ',
          feeBps: 100,
        },
        // onSuccess: onSwapSuccess,
        // onSwapError: onSwapError,
        containerStyles: {
          height: '430px',
          background: 'black',
          padding: '10px',
          zIndex: '0',
          borderRadius: '10px',
          width: '100%', // Default to 100% width
        },
      })
    }

    // Customization logic after Jupiter Swap is initialized
    const swapContainer = document.getElementById('integrated-terminal')
    const intervalId = setInterval(() => {
      const jupiterLogoContainer = swapContainer.querySelector(
        '.flex.items-center.space-x-2'
      )
      if (jupiterLogoContainer) {
        // Replace the Jupiter logo
        // const jupiterLogo = jupiterLogoContainer.querySelector('img')
        // if (jupiterLogo) {
        //   jupiterLogo.src = './assets/logo.svg' // Replace with your logo path
        //   jupiterLogo.alt = 'Coin360 Swap'
        //   jupiterLogo.style.width = '100px' // Adjust width as needed
        //   jupiterLogo.style.height = '30px' // Adjust height as needed
        // }

        // Hide the text next to the logo
        const jupiterText = jupiterLogoContainer.querySelector('span')
        if (jupiterText) {
          jupiterText.style.display = 'none'
        }

        // Add margin to the form
        const formElement = swapContainer.querySelector('form')
        if (formElement) {
          formElement.style.marginTop = '30px' // Adjust the margin as needed
        }

        // Add the v3 label next to the logo
        // const v3Label = document.createElement('div')
        // v3Label.innerText = 'V3'
        // v3Label.style.background = '#7CC07C'
        // v3Label.style.color = 'white'
        // v3Label.style.padding = '2px'
        // v3Label.style.marginLeft = '2px'
        // v3Label.style.borderRadius = '3px'
        // v3Label.style.fontSize = '10px'
        // v3Label.style.position = 'relative'
        // v3Label.style.transform = 'translateY(-50%)' // Move the label up
        // jupiterLogoContainer.appendChild(v3Label)

        clearInterval(intervalId)
      }
    }, 100)
  })

  return (
    <>
      <div
        className={clsx(styles.rightSidebarButtom, {
          [styles.rightSidebarButtomClosed]: !rightSidebar,
        })}
      >
        <Image
          src={`/assets/icons/icon-right-sidebar-${
            rightSidebar ? 'closed' : 'open'
          }.svg`}
          width='20'
          height='20'
          alt='sidebar icon'
          onClick={() => {
            setRightSidebar(!rightSidebar)
            setIsSidebarOpened(!rightSidebar)
          }}
        />
      </div>
      <div
        className={clsx(styles.rightSidebar, {
          [styles.rightSidebarOpen]: rightSidebar,
        })}
      >
        {rightSidebar && (
          <>
            <div className={styles.widgetsContainer}>
              <div id='integrated-terminal' className={styles.swapContainer} />
              {showNewsFeed && <NewsFeedWidget />}
              <div className={styles.signUp}>
                <div className={styles.signUpWrapper}>
                  <div
                    className={styles.signUpText}
                    onClick={() => setIsPopupOpen(true)}
                  >
                    <Image
                      src={`/assets/icons/icon-newsletter-black.svg`}
                      width='22'
                      height='22'
                      alt='newsletter icon'
                    />
                    Sign up for exciting news weekly!
                  </div>
                </div>
              </div>
              {/* <CryptopanicWidget /> */}

              {/* <StatisticsWidget /> */}
            </div>
            <FooterNavbar />
          </>
        )}
      </div>
      {isPopupOpen && <SignUpPopup onClose={() => setIsPopupOpen(false)} />}
    </>
  )
}
