import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getListNewsSelector } from 'src/redux/selectors'
import { getListNews } from 'src/redux/thunks'
import { getDifference } from 'src/utils/time'
import Link from 'next/link'
import { useListPinned, useNewsPinned } from 'src/state'
import { getLatestNewsArticle } from 'src/services/newsArticleServices'
import { getListPinned } from 'src/services/listServices'
import { MainWidget } from '../MainWidget'
import styles from './styles.module.scss'

export const NewsFeedWidget = () => {
  const data = useSelector(getListNewsSelector)
  const dispatch = useDispatch()
  const latestNewsItem = useNewsPinned()
  const pinnedList = useListPinned()

  useEffect(() => {
    if (!pinnedList) {
      dispatch(getListPinned())
    }
    if (!latestNewsItem) {
      dispatch(getLatestNewsArticle())
    }
    if (data.length === 0) {
      dispatch(getListNews())
    }
  }, [data, pinnedList, latestNewsItem, dispatch])

  const renderLatestItem = item => {
    const baseUrl = 'https://coin360.com/list/'
    return (
      <div className={styles.item}>
        <Link
          className={styles.titleItem}
          rel='noopener noreferrer nofollow'
          target='_blank'
          href={`${baseUrl}${item.data?.attributes?.slug}`}
          passHref
        >
          {item.data?.attributes?.title}
        </Link>
        <Link href={`${baseUrl}${item.data?.attributes?.slug}`} passHref>
          <div className={styles.info}>
            <div className={styles.latestName}>COIN360</div>
            <div className={styles.readNow}>Read now!</div>
          </div>
        </Link>
      </div>
    )
  }

  const renderNewsItem = item => {
    const date = getDifference(item.date)
    return (
      <div className={styles.item}>
        <Link
          className={styles.titleItem}
          rel='noopener noreferer nofollow'
          target='_blank'
          href={item.link}
          passHref
        >
          {item.title}
        </Link>
        <div className={styles.info}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.date}>{date}</div>
        </div>
      </div>
    )
  }

  return (
    <MainWidget title={'Live news feed'} defaultOpen={false}>
      <div className={styles.newsContainer}>
        {latestNewsItem?.data &&
          Object.keys(latestNewsItem.data).length > 0 && (
            <>{renderLatestItem(latestNewsItem)}</>
          )}

        {pinnedList?.data && Object.keys(pinnedList.data).length > 0 && (
          <>{renderLatestItem(pinnedList)}</>
        )}

        {data.map(item => renderNewsItem(item))}
      </div>
    </MainWidget>
  )
}
