import React, { useState } from 'react'
import styles from './styles.module.scss'
import { NewsFeedWidget } from '../RightSidebar/Widgets/NewsFeed'

const NewsModal = ({ onClose }) => {
  const [isClosed, setIsClosed] = useState(false)

  const handleClose = () => {
    setIsClosed(true)
    onClose()
  }

  return (
    <div className={`${styles.modalWrapper} ${isClosed ? styles.closed : ''}`}>
      <div className={`${styles.main} ${isClosed ? styles.closed : ''}`}>
        <span onClick={handleClose} className={styles.closeButton}>
          &times;
        </span>
        <div className={styles.newsSection}>
          <NewsFeedWidget />
        </div>
      </div>
    </div>
  )
}

export default NewsModal
