import Image from 'next/image'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const CirculatingSupply = ({ header }) => {
  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.circulatingSupply.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.circulatingSupply.text1')}</p>
          <p>{intl.t('faq.pages.circulatingSupply.text2')}</p>
          <Image
            src='/assets/images/faq/circulating-supply.png'
            width='770'
            height='98'
            alt='The comparison of Bitcoin Cash’s price and circulating supply with Dogecoin’s ones.'
          />
          <p>{intl.t('faq.pages.circulatingSupply.text3')}</p>
        </section>
      </div>
    </>
  )
}

export default CirculatingSupply
