import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import {
  RouteEventType,
  WalletEventTypes,
  Widget,
  WidgetConfig,
  widgetEventEmitter,
  WidgetEvents,
} from '@rango-dev/widget-embedded'
import useAuth from 'src/hooks/useAuth'
import {
  saveAnonymUserTransaction,
  saveUserTransaction,
} from 'src/services/userServices'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/utils/localStorage'

// 1) No need to keep loyalty details in local state here,
//    as we are only "counting" (saving) not displaying.

const config = {
  title: 'Coin360',
  apiKey: '2680af7d-9f6e-4ed4-a547-ac05368437fe',
  walletConnectProjectId: 'e24844c5deb5193c1c14840a7af6a40b',
  affiliate: {
    ref: 'Gptzic',
    percent: 0.3,
    wallets: {
      ETH: '0x0A6687A6d29495A3BF9b2672606123DFbC87103D',
      BSC: '0x0A6687A6d29495A3BF9b2672606123DFbC87103D',
      POLYGON: '0x0A6687A6d29495A3BF9b2672606123DFbC87103D',
      SOLANA: '7VjQTLkuJ7EVSJWG1EtAqW2oA9hrdhAs8ghfxYMXNPFz',
    },
  },
  theme: {
    mode: 'dark',
    singleTheme: true,
    colors: {
      dark: {
        background: '#000',
        primary: '#1C3CF1',
      },
    },
  },
}

export const RangoSwap = () => {
  const params = useSearchParams()
  const { isAuthorized } = useAuth()
  const [walletId, setWalletId] = useState(null)
  const [isReady, setReady] = useState(false)

  // Mark the widget as ready when mounted
  useEffect(() => {
    setReady(true)
  }, [])

  // Listen for widget events + handle referrer token
  useEffect(() => {
    // Store the referrer token in localStorage if present
    const referrerToken = params.get('referrer')
    if (referrerToken) {
      setLocalStorageItem('referrerToken', referrerToken)
    }

    // Setup widget event listeners
    widgetEventEmitter.on(WidgetEvents.RouteEvent, routeEvent => {
      const { event } = routeEvent
      if (event.type === RouteEventType.SUCCEEDED) {
        onSwapSuccess(routeEvent)
      } else if (event.type === RouteEventType.FAILED) {
        console.log('swap error: ', event)
      }
    })

    widgetEventEmitter.on(WidgetEvents.WalletEvent, walletEvent => {
      // Keep track of user’s connected wallet
      if (
        walletEvent.type === WalletEventTypes.CONNECT &&
        walletEvent.payload.accounts.length > 0
      ) {
        const address = walletEvent.payload.accounts[0].address
        setWalletId(address)
      }
    })

    // Clean up event listeners on unmount
    return () => {
      widgetEventEmitter.off(WidgetEvents.RouteEvent)
      widgetEventEmitter.off(WidgetEvents.WalletEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSwapSuccess = ({ event, route }) => {
    if (route.steps.length === 0) return

    const txId = route.requestId
    const step = route.steps[0]
    const { inputAmount, inputAmountUsd } = event
    const inputSymbol = step.fromSymbol

    const sourceToken = step.fromSymbol
    const destinationToken =
      route.steps[route.steps.length - 1].toSymbol || 'Unknown'

    const referrerToken = getLocalStorageItem('referrerToken')

    // Save transaction data (count points).
    // RangoSwap does the "counting" part, but does not display them here.
    if (isAuthorized) {
      saveUserTransaction(
        txId,
        inputSymbol,
        inputAmount,
        inputAmountUsd,
        referrerToken
      )
    } else {
      saveAnonymUserTransaction(
        walletId || step.transaction.from,
        txId,
        inputSymbol,
        inputAmount,
        inputAmountUsd,
        referrerToken
      )
    }

    // Example: push swap_completed event for analytics/Tag Manager
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'swap_completed',
        source_token: sourceToken,
        destination_token: destinationToken,
        input_amount: inputAmount,
      })
    }
  }

  return (
    <div>
      {/* Render the Rango widget only after it’s ready */}
      {isReady && <Widget config={config} />}
    </div>
  )
}
